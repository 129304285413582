<template>
  <div class="col-xl-4 col-llg-6 col-lg-6 col-md-6 col-sm-12 prcustom product_col" :class='product_category' :id='product_code' :data-price='product_code'>
    <div class="card cardtableview cardclassicview" :class='zeroproductimg' :data-price='product_price'>
      <div class='cardtablediv' :class='backgroundtransparent'>
        <div class="flexcardbody">
          <div class="flexdesctitle">
            <svg v-b-tooltip.hover :title='product_description' aria-labelledby="svg-inline--fa-title-gwEmcLJQ3qlg" data-prefix="fas" data-icon="circle-info" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-circle-info fadetailsdesc tooltipwithdescription" aria-hidden="true" focusable="false" data-fa-i2svg=""><path fill="currentColor" d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c17.67 0 32 14.33 32 32c0 17.67-14.33 32-32 32S224 177.7 224 160C224 142.3 238.3 128 256 128zM296 384h-80C202.8 384 192 373.3 192 360s10.75-24 24-24h16v-64H224c-13.25 0-24-10.75-24-24S210.8 224 224 224h32c13.25 0 24 10.75 24 24v88h16c13.25 0 24 10.75 24 24S309.3 384 296 384z"></path></svg>
            <h4 class="cardtittleprname mll-1">{{product_name}}</h4>
            <div class="ml-auto">
              <img :src='product_img' alt="product logo" :data-imgprov='product_code' :class='product_code' class="logocard">
            </div>
          </div>
          <span class="shortdescsp">{{product_short_description}}</span>
        </div>
      </div>
      <div class="divprodctselectclassic cardtablediv" :class="backgroundtransparent">
        <div>
          <span class="titleprotablepriceclassic">{{product_price}} {{product_currency}}</span>
          <div style="margin-top:5px;">
            <b-form-checkbox
              @change="storageOfferName()"
              :id="'offercp_'+product_code"
              :value="product_code"
              :data-price="product_price"
              name="products_arr[]"
            >
              Add to offer
            </b-form-checkbox>
          </div>
        </div>
        <input v-if="!zeropricebtn" @click='$emit("selectProductChange", $event.target)' :id="'button-tab'+product_code" :name="'tab'+product_code" type="button" value="Select" class="btn btn-success font-bold  buttonPrice" :data-product='product_code' :data-productname='product_name' :data-productprice='product_price' :data-productcategory='product_category'> 
      </div>
      <div class=" showmorecoverages_bg showmorecovsclassic" :class="backgroundtransparent" @click="primarylist_show=!primarylist_show">
        <h6 class="titleprotable">Primary Coverages</h6>
        <font-awesome-icon icon="fa-solid fa-chevron-up" class="mtt-1 fascovs ml-1" size="lg" v-if="primarylist_show"/>
        <font-awesome-icon icon="fa-solid fa-chevron-down" class="mtt-1 fascovs ml-1" size="lg" v-else/>
      </div>
      <!-- v-if="primarylist_show" -->
      <div class="cardtablediv cardtabledivprimaryclassic" :class="backgroundtransparent" v-if="primarylist_show">
        <div v-for="p in primary" :key="p.index">
          <ul class="list-group list-group-horizontal list-froup-classic">
            <li class="list-group-item" :class="backgroundtransparent"><span class="listspanttt">Code</span>{{p.coverage_code}}</li>
            <li class="list-group-item" :class="backgroundtransparent"><span class="listspanttt">Excess</span>{{p.coverage_excess}}</li>
            <li class="list-group-item" :class="backgroundtransparent"><span class="listspanttt">Limit</span>{{p.coverage_limit}}</li>
          </ul>
        </div>
      </div>

      <div class=" showmorecoverages_bg showmorecovsclassic" :class="backgroundtransparent" @click="secondarylist_show=!secondarylist_show">
        <div class="d-flex align-items-center">
          <h6 class="titleprotable">Secondary Coverages</h6>
          <font-awesome-icon icon="fa-solid fa-chevron-up" class="mtt-1 fascovs ml-1" size="lg" v-if="secondarylist_show"/>
          <font-awesome-icon icon="fa-solid fa-chevron-down" class="mtt-1 fascovs ml-1" size="lg" v-else/>
        </div>
      </div>
      <div class="cardtablediv cardtabledivsecondaryclassic" :class="backgroundtransparent" v-if="secondarylist_show">
        <div v-for="s in secondary" :key="s.index">
          <ul class="list-group list-group-horizontal list-froup-classic">
            <li class="list-group-item" :class="backgroundtransparent"><span class="listspanttt">Code</span>{{s.coverage_code}}</li>
            <li class="list-group-item" :class="backgroundtransparent"><span class="listspanttt">Excess</span>{{s.coverage_excess}}</li>
            <li class="list-group-item" :class="backgroundtransparent"><span class="listspanttt">Limit</span>{{s.coverage_limit}}</li>
          </ul>
        </div>
      </div>
      <div role="group" class="b-avatar-group pt-2 pb-2">
        <div style='display: flex;justify-content: center;align-items: center;width: 100%;' v-if='product_documents[product_code]'>
          <a style='margin:0 10px;border-radius:6px;' :href="product_documents[product_code].tob_link" target="_blank" class="btn btn-sm btn-outline-secondary tooltipwithdescription" v-b-tooltip.hover :title="product_documents[product_code].tob_title">TOB</a>
          <a style='margin:0 10px;border-radius:6px;' :href="product_documents[product_code].pwo_link" target="_blank" class="btn btn-sm btn-outline-secondary tooltipwithdescription" v-b-tooltip.hover :title="product_documents[product_code].pwo_title">PWO</a>
          <a style='margin:0 10px;border-radius:6px;' :href="product_documents[product_code].ipid_link" target="_blank" class="btn btn-sm btn-outline-secondary tooltipwithdescription" v-b-tooltip.hover :title="product_documents[product_code].ipid_title">IPID
          </a>
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
  import { product_documents, viewsProductsMixin } from '@/products';
  import {
    VBTooltip,
    BFormCheckbox
  } from 'bootstrap-vue'
  export default {
    mixins: [viewsProductsMixin],
    components: {
      VBTooltip,
      BFormCheckbox
    },
    props:['product_price','product_currency','product_currency_symbol','product_category','product_code','product_description','product_name','product_short_description','primary','secondary','resCheckName','destCheckName','startDate','daysNum','agesArr'],
    directives: {
      'b-tooltip': VBTooltip,
    },
    data() {
      return {
        primarylist_show: true,
        secondarylist_show: false,
        zeroproductimg: null,
        backgroundtransparent: null,
        zeropricebtn: false,
        product_documents,
        product_img: null,
        col_img: require('@/assets/images/quotation/globeinsure.png'),
        sompo_img: require('@/assets/images/quotation/sompo.png'),
        mon_img: require('@/assets/images/quotation/mondial.png'),
        kem_img: require('@/assets/images/quotation/kemter.png'),
        battle_img: require('@/assets/images/quotation/battleface.png'),
      }
    },
    created() {
      this.setImg();
    },
    mounted() {
      this.checkProducts("#");
      this.zeroProducts();
    }
  }
</script>